import '../../node_modules/swiper/dist/css/swiper.min.css';
import '../scss/style.scss';
import Swiper from '../../node_modules/swiper';

var swiper = new Swiper('.swiper-container', {
	slidesPerView: 'auto',
	spaceBetween: 0,
//	centeredSlides: true,
	pagination: {
		el: '.swiper-pagination',
		dynamicBullets: true,
	},
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
});

+function($){
	$(function() {
		var h = $('.section.is-item .items').height();
		$('.section.is-item .more').on('click', function(){
			var self = $(this);
			var section = self.closest('.section');
			var items = section.find('.items');
			if ( 'open' == section.attr('data-state') ) {
				section.attr('data-state', 'close');
				items.animate({height: 639 < $(window).width() ? 274 : 282});
			}
			else {
				section.attr('data-state', 'open');
				items.animate({height: h});
			}
			return false;
		}).trigger('click');
		$(window).resize();
	});
}(jQuery);